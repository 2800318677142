import "./styles.scss";
import { Data } from "../Statistic/interfaces";

const SimpleText = (props: { texts: Data[] }) => {
  return (
    <div className="text-container">
      {props.texts.map((text: Data) => (
        <p>{text.name}</p>
      ))}
    </div>
  );
};

export default SimpleText;
