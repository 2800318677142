import { useEffect } from "react";

// Custom hook to get consent status (this should match how it's used in your slider)
const useCookieConsent = () => {
  // Mock implementation of useCookieConsent - replace with your actual hook
  // This should return an object with preference consent status
  return { preferences: true, statistics: false }; // Example, replace with real hook
};

// Your function to open the cookie consent settings, replace with the actual function
const openCookieConsent = () => {
  // Logic to open cookie settings
  console.log("Opening cookie consent settings...");
};

const VimeoVideo = () => {
  const consent = useCookieConsent();

  useEffect(() => {
    if (consent.statistics) {
      // Load additional analytics or scripts if required, similar to the slider component
    }
  }, [consent.statistics]);

  if (consent.preferences) {
    return (
      <div className="video">
        <iframe
          src="https://player.vimeo.com/video/1002692127?h=9f8e9e2ebc&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Dokumentation Hofbesuch: Geflügel schmeckt - Made in Germany"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          data-cookieconsent="preferences"
        ></iframe>
      </div>
    );
  }

  return (
    <div className="cookies">
      <p>
        Sie müssen den Präferenz-Cookies zustimmen, um diesen Inhalt zu sehen.
      </p>
      <button onClick={openCookieConsent}>
        Cookie-Einstellungen aktualisieren
      </button>
    </div>
  );
};

export default VimeoVideo;
