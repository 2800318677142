import { StatisticDataType } from "./interfaces";

export const statisticData: StatisticDataType[] = [
  {
    name: "Bewusstes Essen",
    unit: "%",
    question: "Wie bewusst entscheiden Sie, was Sie täglich essen?",
    statement:
      "Unter den jungen Erwachsenen hat bewusstes Essen einen sehr hohen Stellenwert.",
    data: [
      { name: "Sehr bewusst", value: 35.2 },
      { name: "Eher bewusst", value: 42.3 },
      { name: "Unentschieden", value: 17.1 },
      { name: "Eher nicht bewusst", value: 4.8 },
      { name: "Gar nicht bewusst", value: 0.6 },
    ],
  },
  {
    name: "Beliebteste Fleischart",
    unit: "%",
    question: "Welche Fleischart essen Sie am häufigsten?",
    statement: "Geflügel bei Generation Z mit Abstand beliebtestes Fleisch.",
    data: [
      { name: "Geflügel", value: 46.7 },
      { name: "Rind", value: 23.9 },
      { name: "Schwein", value: 16.9 },
      { name: "Lamm", value: 2.7 },
      { name: "Wild", value: 1.2 },
      { name: "Keine Angabe", value: 7.9 },
      { name: "Anderes Fleisch", value: 0.7 },
    ],
  },
  {
    name: "Kriterien und Prioritäten bei Ernährung",
    unit: "%",
    question: "Worauf legen Sie bei Ihrer Ernährung besonderen Wert?",
    statement:
      "Unter den jungen Erwachsenen hat bewusstes Essen einen sehr hohen Stellenwert.",
    widthYAxis: 100,
    data: [
      { name: "Geschmack", value: 76.7 },
      { name: "Günstiger Preis", value: 54.3 },
      { name: "Nährstoffe", value: 50.3 },
      { name: "Einfache Zubereitung", value: 48.1 },
      { name: "Lange Haltbarkeit der Lebensmittel", value: 27.3 },
      { name: "Regionale Herkunft der Lebensmittel", value: 25.6 },
      { name: "Nachhaltige Lebensmittelproduktion", value: 23.6 },
      { name: "Auf etwas anderes", value: 3.3 },
      { name: "Nichts / Weiß nicht", value: 0.6 },
    ],
  },
  {
    name: "Häufigkeit Geflügel-Genuss",
    unit: "%",
    question:
      "Wie oft in der Woche essen Sie Geflügelgerichte (z.B. Hähnchen, Pute, Ente)?",
    statement:
      "80 Prozent essen Hähnchen, Pute, Ente & Co. mindestens einmal in der Woche.",
    data: [
      { name: "Täglich", value: 7.3 },
      { name: "An 5-6 Tagen", value: 9.4 },
      { name: "An 3-4 Tagen", value: 28.9 },
      { name: "An 1-2 Tagen", value: 35.4 },
      { name: "Seltener", value: 15.4 },
      { name: "Weiß nicht", value: 3.6 },
    ],
  },
  {
    name: "Warum Geflügel?",
    unit: "%",
    question:
      "Welche Gründe sprechen für Sie persönlich für den Konsum von Geflügelfleisch?",
    statement:
      "Geflügelfleisch wird von der Gen Z vor allem wegen seines Geschmacks und Nährwertes geschätzt.",
    data: [
      { name: "Guter Geschmack", value: 62.2 },
      { name: "Proteinreich", value: 58.1 },
      { name: "Gute Nährwerte", value: 39.0 },
      { name: "Leichte Zubereitung", value: 35.7 },
      { name: "Fettarm", value: 35.5 },
      { name: "Gesundes Nahrungsmittel", value: 32.2 },
      { name: "Gute Ökobilanz", value: 7.3 },
      { name: "Keine der Genannten", value: 3.7 },
      { name: "Weiß nicht", value: 1.4 },
    ],
  },
  {
    name: "Geflügel und gesunde Ernährung",
    unit: "%",
    question:
      "Wie wichtig ist Geflügelfleisch Ihrer Meinung nach für eine gesunde Ernährung?",
    statement:
      "70 Prozent der befragten jungen Erwachsenen stufen Geflügelfleisch als bedeutsam für eine gesunde Ernährung ein.",
    data: [
      { name: "Sehr wichtig", value: 24.8 },
      { name: "Eher wichtig", value: 44.5 },
      { name: "Unentschieden", value: 22.2 },
      { name: "Weniger wichtig", value: 3.8 },
      { name: "Gar nicht wichtig", value: 4.7 },
    ],
  },
  {
    name: "In Zukunft noch mehr Geflügelfleisch",
    unit: "%",
    question:
      "Werden Sie in den kommenden zwei Jahren eher mehr oder eher weniger Geflügel essen?",
    statement:
      "30 Prozent der Gen Z-Befragten wollen in den kommenden zwei Jahren noch mehr Geflügelfleisch essen zu wollen.",
    data: [
      { name: "Eindeutig mehr", value: 7.2 },
      { name: "Eher mehr", value: 23.8 },
      { name: "Ungefähr genauso viel / wenig wie bisher", value: 50.8 },
      { name: "Eher weniger", value: 11.0 },
      { name: "Eindeutig weniger", value: 5.3 },
      { name: "Weiß nicht", value: 1.9 },
    ],
  },
  {
    name: "Über die Studie",
    unit: "text",
    question: "Repräsentative Umfrage unter „Generation Z“ in Deutschland",
    statement:
      "Welche Vorlieben hat die Gen Z, wenn es um den Konsum von Geflügelfleisch geht?",
    data: [
      {
        name: "Das Meinungs- und Marktforschungsunternehmen Civey hat für die Deutsche Geflügelwirtschaft vom 15. bis 22. Juli rund 1.000 16- bis 29-Jährige in Deutschland online befragt. Die Ergebnisse sind unter statistischen Bedingungen repräsentativ für die befragte Zielgruppe. Weitere Informationen zur Umfrage stellen wir auf Anfrage gern zur Verfügung.",
      },
    ],
  },
];
