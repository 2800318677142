import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import SwiperCore from 'swiper'; // Import the Swiper type
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "./VimeoSlider.css"; // Import the CSS file

// Extend the Window interface for TypeScript

const VimeoSlider = () => {
  const [swiperInitialized, setSwiperInitialized] = useState(false);
  const videoRefs = useRef<(HTMLIFrameElement | null)[]>([]); // Array of refs for each video


  useEffect(() => {
    // Function to initialize Swiper after Cookiebot consent is given
    const initializeSwiper = () => {
      setSwiperInitialized(true);
    };

    // Check if Cookiebot consent is already given
    // TO DO: for local testing
    // if (window.CookieConsent && window.CookieConsent.hasConsented)
    if (window.Cookiebot) {
      initializeSwiper();
    } else {
      // Add event listener for Cookiebot's consent event
      window.addEventListener('CookieConsentEvent', initializeSwiper);
    }

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('CookieConsentEvent', initializeSwiper);
    };
  }, []);

  const handleSlideChange = (swiper: SwiperCore) => {
    videoRefs.current.forEach((video, index) => {
      if (video && index !== swiper.activeIndex) {
        video.contentWindow?.postMessage('{"method":"pause"}', '*');
      }
    });
  };

  // Only render the Swiper component after initialization
  return (
    <>
      {swiperInitialized && (
    <Swiper
      modules={[Navigation, Pagination]} // Include both Navigation and Pagination modules
      spaceBetween={10}
      slidesPerView={1.5}
      navigation // Enable navigation arrows
      initialSlide={3} // Start with the second slide centered
      centeredSlides={true} // Center the first slide
      pagination={{ clickable: true }} // Enables pagination bullets that are clickable
      cssMode={false} // Ensure cssMode is disabled to allow swiping
      breakpoints={{
        640: { slidesPerView: 1 },
        768: { slidesPerView: 1.5 },
        1024: { slidesPerView: 1.5 },
      }}
      onSlideChange={handleSlideChange} // Handle slide change event
    >
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[0] = el)}
          src="https://player.vimeo.com/video/1002663499?h=fa312637bf&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Tiziana: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[1] = el)}
          src="https://player.vimeo.com/video/1002668356?h=b4c2cd366e&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Uli: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[2] = el)}
          src="https://player.vimeo.com/video/1002674500?h=0cd7847bc8&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Marcel: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[3] = el)}
          src="https://player.vimeo.com/video/1002675962?h=d66f56eae4&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Carsten: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[4] = el)}
          src="https://player.vimeo.com/video/1002678765?h=8c7a916bd4&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Heike: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      <SwiperSlide>
        <iframe
          ref={(el) => (videoRefs.current[5] = el)}
          src="https://player.vimeo.com/video/1002681376?h=576e1da266&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          allow="fullscreen"
          title="Bekenner-Statement Rigo: Geflügel schmeckt - Made in Germany"
          allowFullScreen
        ></iframe>
      </SwiperSlide>
      {/* Add more slides as needed */}
    </Swiper>
     )}
    </>
  );
};

export default VimeoSlider;
