import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./desktop.scss";
import "./mobile.scss";
import Statistic from "./Statistic";
import VimeoSlider from "./VimeoSlider/VimeoSlider";
import useCookieConsent from "./hooks/useCookieConsent";
import VimeoVideo from "./VimeoVideo";

// Extend the Window interface to include Google Analytics
declare global {
  interface Window {
    ga: ((...args: any[]) => void) | undefined;
  }
}

const loadGoogleTagManager = () => {
  const script = document.createElement("script");
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TNDZXSKS');
  `;
  document.head.appendChild(script);
};

const loadGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-1GDHSBD4K8`;
  document.head.appendChild(script);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-1GDHSBD4K8');
  `;
  document.head.appendChild(inlineScript);
};




// Function to trigger the Cookiebot consent dialog
const openCookieConsent = () => {
  console.log("Attempting to open the Cookiebot consent renewal.");

  if (window.Cookiebot?.renew) {
    console.log("Cookiebot consent renew is available. Opening the renewal.");
    window.Cookiebot.renew();
  } else {
    if (!window.Cookiebot) {
      console.warn("Cookiebot object is not available on the window.");
    } else if (!window.Cookiebot.renew) {
      console.warn("Cookiebot.renew is not available.");
    } else {
      console.warn(
        "An unexpected issue occurred while trying to open the Cookiebot consent renewal."
      );
    }
  }
};

// Render VimeoSlider or a prompt to accept preferences cookies
const RenderVimeoSlider = () => {
  const consent = useCookieConsent();

  useEffect(() => {
    if (consent.statistics) {
      loadGoogleTagManager();
      loadGoogleAnalytics();
    }
  }, [consent.statistics]);

  if (consent.preferences) {
    return (
      <React.StrictMode>
        <VimeoSlider />
      </React.StrictMode>
    );
  }

  return (
    <div className="cookies">
      <h3>
        Sie müssen den Präferenz-Cookies zustimmen, um diesen Inhalt zu sehen.
      </h3>
      <button className="cookieButton" onClick={openCookieConsent}>
        Cookie-Einstellungen aktualisieren
      </button>
    </div>
  );
};

// Render VimeoSlider or a prompt to accept preferences cookies
const RenderVimeoVideo = () => {
  // Get the container element
  const consent = useCookieConsent();
  if (consent.preferences) {
    return (
      <React.StrictMode>
        <VimeoVideo />
      </React.StrictMode>
    );
  }

  return (
    <div className="cookies">
      <h3>
        Sie müssen den Präferenz-Cookies zustimmen, um diesen Inhalt zu sehen.
      </h3>
      <button className="cookieButton" onClick={openCookieConsent}>
        Cookie-Einstellungen aktualisieren
      </button>
    </div>
  );
};

// Render Statistic unconditionally (no cookies needed)
const RenderStatistic = () => {
  return (
    <React.StrictMode>
      <Statistic />
    </React.StrictMode>
  );
};

// Render VimeoSlider into #video-slider
if (document.getElementById("video-slider")) {
  ReactDOM.createRoot(
    document.getElementById("video-slider") as HTMLElement
  ).render(<RenderVimeoSlider />);
}

// Render VimeoVideo into #vimeo-video-container
if (document.getElementById("vimeo-video-container")) {
  ReactDOM.createRoot(
    document.getElementById("vimeo-video-container") as HTMLElement
  ).render(<RenderVimeoVideo />);
}

// Render Statistic into #statistics
if (document.getElementById("statistics")) {
  ReactDOM.createRoot(
    document.getElementById("statistics") as HTMLElement
  ).render(<RenderStatistic />);
}
