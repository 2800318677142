import { useEffect, useState } from "react";

interface CookieConsent {
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

declare global {
  interface Window {
    Cookiebot?: any; // Define Cookiebot globally
  }
}

const useCookieConsent = () => {
  const [consentGiven, setConsentGiven] = useState<CookieConsent>({
    preferences: false,
    statistics: false,
    marketing: false,
  });

  useEffect(() => {
    const updateConsentState = () => {
      if (window.Cookiebot && window.Cookiebot.consent) {
        const newConsentState = {
          preferences: window.Cookiebot.consent.preferences,
          statistics: window.Cookiebot.consent.statistics,
          marketing: window.Cookiebot.consent.marketing,
        };
        console.log("Updating consent state:", newConsentState);
        setConsentGiven(newConsentState);
      } else {
        console.warn("Cookiebot or Cookiebot.consent is not available.");
      }
    };

    console.log("Initializing CookieConsent hook.");

    // Initial consent state when the consent is ready
    window.addEventListener("CookiebotOnConsentReady", () => {
      console.log("CookiebotOnConsentReady event triggered.");
      updateConsentState();
    });

    // Update consent state when user accepts cookies
    window.addEventListener("CookiebotOnAccept", () => {
      console.log("CookiebotOnAccept event triggered.");
      updateConsentState();
    });

    // Debugging: Log if Cookiebot is not ready
    if (!window.Cookiebot) {
      console.warn("Cookiebot is not available on the window object.");
    } else {
      console.log("Cookiebot is available:", window.Cookiebot);
    }

    return () => {
      console.log("Cleaning up event listeners in CookieConsent hook.");
      window.removeEventListener("CookiebotOnConsentReady", updateConsentState);
      window.removeEventListener("CookiebotOnAccept", updateConsentState);
    };
  }, []);

  return consentGiven;
};

export default useCookieConsent;
