import "./styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";

const CarouselSlider = (props: {
  id: string;
  children: JSX.Element[];
  onChange?: (index: number, item: React.ReactNode) => void;
}) => {
  const getPercent = (w: number) =>
    Math.max(
      60,
      w < 1000
        ? ((w - 80 + 20) / w) * 100 // (w - (--side-padding * 2) + (padding in slide * 2)) / w
        : 60
    );

  const [percent, setPercent] = useState<number>(getPercent(window.innerWidth));

  window.addEventListener("resize", () => {
    setPercent(getPercent(window.innerWidth));
  });

  const handleSlideChange = (direction: string) => {
    window.gtag("event", "slide_change", {
      event_category: "Carousel",
      event_label: `Slide changed: ${direction}`,
    });
  };

  return (
    <Carousel
      showStatus={false}
      centerMode={true}
      centerSlidePercentage={percent}
      infiniteLoop={false}
      showThumbs={false}
      verticalSwipe="natural"
      swipeScrollTolerance={30}
      preventMovementUntilSwipeScrollTolerance
      useKeyboardArrows={true}
      key={`carousel-${props.id}-${percent}`} // re-render on resize because key changes
      onChange={props.onChange}
      renderArrowNext={(onClickHandler) => (
        <button
          type="button"
          onClick={() => {
            onClickHandler();
            handleSlideChange("next");
          }}
          aria-label={"nächste Folie"}
          style={{ width: `calc(${(100 - percent) / 2}% - 10px)` }}
          className="control-arrow control-next"
        />
      )}
      renderArrowPrev={(onClickHandler) => (
        <button
          type="button"
          onClick={() => {
            onClickHandler();
            handleSlideChange("prev");
          }}
          aria-label={"vorherige Folie"}
          style={{ width: `calc(${(100 - percent) / 2}% - 10px)` }}
          className="control-arrow control-prev"
        />
      )}
    >
      {props.children}
    </Carousel>
  );
};

export default CarouselSlider;
