import './styles.scss';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import { StatisticDataType } from '../Statistic/interfaces';
import SimpleText from '../SimpleText';

const SimpleBarChart = (props: { data: StatisticDataType }) => {
  return (
    <div
      className="chart-container"
    >
      {props.data.question && <h3>{props.data.question}</h3>}
      <ResponsiveContainer>
        {props.data.unit === 'text' ? (
          <SimpleText texts={props.data.data} />
        ) : (
          <BarChart
            data={props.data.data}
            layout="vertical"
            margin={{
              top: 0,
              right: 40,
              left: 50,
              bottom: 0
            }}
          >
            <XAxis type="number" tick={false} axisLine={false} />
            <YAxis
              type="category"
              dataKey="name"
              axisLine={{ stroke: 'var(--secondary-accent)' }}
              tickLine={false}
              width={props.data.widthYAxis}

              tick={{ textAnchor: 'end' }}
            />
            <Bar
              dataKey="value"
              fill="var(--secondary-accent)"
              radius={[0, 25, 25, 0]}
            >
              <LabelList
                dataKey="value"
                position="right"
                formatter={(value: string) =>
                  `${value}${props.data.unit || ''}`
                }
              />
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
      {props.data.statement && (
        <h2 id="statementStyle">{props.data.statement}</h2>
      )}
    </div>
  );
};

export default SimpleBarChart;
