import { useState } from "react";
import "./styles.scss";
import { StatisticDataType } from "./interfaces";
import { statisticData } from "./data";
import SimpleBarChart from "../SimpleBarChart";
import CarouselSlider from "../CarouselSlider";
import useCookieConsent from "../hooks/useCookieConsent";

const Statistic = () => {
  const consent = useCookieConsent();
  const [choosenData, setChosenData] = useState<StatisticDataType>(
    statisticData[0]
  );
  const maxWidthMobile = 1000;
  const [, setMobileLayout] = useState<boolean>(
    window.innerWidth < maxWidthMobile
  );

  window.addEventListener("resize", () =>
    setMobileLayout(window.innerWidth < maxWidthMobile)
  );

  const handleChange = (dataSetName: string) => {
    if (consent.statistics) {
      window.gtag("event", "data_set_change", {
        event_category: "Statistic",
        event_label: `Dataset changed: ${dataSetName}`,
      });
    }
  };

  if (window.innerWidth > 1000) {
    return (
      <>
        <div className="buttons">
          {statisticData.map((dataSet) => (
            <button
              onClick={() => {
                setChosenData(dataSet);
                handleChange(dataSet.name);
              }}
              className={dataSet === choosenData ? "active" : ""}
            >
              {dataSet.name}
            </button>
          ))}
        </div>
        <SimpleBarChart data={choosenData} />
      </>
    );
  }

  return (
    <CarouselSlider
      id="statisitc-carousel"
      onChange={(index: number) => {
        handleChange(statisticData[index].name);
      }}
    >
      {statisticData.map((dataSet) => (
        <SimpleBarChart
          key={`statistic-carousel-${dataSet.name}`}
          data={dataSet}
        />
      ))}
    </CarouselSlider>
  );
};

export default Statistic;
